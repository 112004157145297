
const QUANTISED_SCROLL_ACTION_TYPES = {
  READ_PAGE: 'READ_PAGE',
  SET_STEP_INDEX: 'SET_STEP_INDEX',
  CLEAR_STEP_INDICES: 'CLEAR_STEP_INDICES',
  READ_HASH: 'READ_HASH',
  NAV_UP: 'NAV_UP',
  NAV_DOWN: 'NAV_DOWN',
  NAV_TO: 'NAV_TO',
  SCROLL_TO: 'SCROLL_TO',
};

export default QUANTISED_SCROLL_ACTION_TYPES;
