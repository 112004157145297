const PRICING_ACTION_TYPES = {
  START: 'PRICING_START',
  SET_ANSWER: 'SET_ANSWER',
  SET_NAME: 'SET_NAME',
  SET_CHARGE: 'SET_CHARGE',
  SET_ESTIMATE: 'SET_ESTIMATE',
  SET_ESTIMATE_BUDGET: 'SET_ESTIMATE_BUDGET',
  SET_EMAIL: 'SET_EMAIL',
  SET_PHONE: 'SET_PHONE',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_PATCH: 'SET_PATCH',
  SIGN_UP: 'SIGN_UP',
  SET_OPTIONAL_INFO: 'SET_OPTIONAL_INFO',
  SET_CLID: 'SET_CLID',
  INCR_LINK_COUNT: 'INCR_LINK_COUNT',
  RECEIVE_OBJECT: 'RECEIVE_OBJECT',
  SET_ID: 'SET_ID',
  SET_TSUNSUBSCRIBE: 'SET_TSUNSUBSCRIBE',
  SET_INITIALESTIMATE: 'SET_INITIALESTIMATE',
  SET_DEVSTEPS: 'SET_DEVSTEPS',
  SET_SAMPLES: 'SET_SAMPLES',
  SET_COURSE1: 'SET_COURSE1',
  SET_COURSE2: 'SET_COURSE2',
  SET_MONTHLYFEE: 'SET_MONTHLYFEE',
  SET_AMOUNT: 'SET_AMOUNT',
  SET_VERIFYCOUNT: 'SET_VERIFYCOUNT',
  SET_FUNDING: 'SET_FUNDING',
  IN_DEV: 'IN_DEV',
  SET_TEMP_EMAIL: 'SET_TEMP_EMAIL',
  SET_FORUM_ID: 'SET_FORUM_ID',
  SET_TOTAL_COMMENTS: 'SET_TOTAL_COMMENTS',
  SET_REFETCH: 'SET_REFETCH',
  SET_VERIFY_CODE: 'SET_VERIFY_CODE',
};

export default PRICING_ACTION_TYPES;
