/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onRouteUpdate = ({ location, prevLocation }) => {
  try {
    const path = location.pathname.split('/cost-to-make-an-app/')[1];
    console.log({ path, prevLocation });
    if (path && path === 'platforms/') {
      // navigate('/cost-to-make-an-app/platforms');
    } else if (path && path.includes('login')) {
      // window.location.href = '/cost-to-make-an-app/login';
      // window.location.reload();

      // navigate('/cost-to-make-an-app/platforms');
    }
  } catch (err) {
    console.log({ err });
  }
  // If first navigation update and user didn't scroll, append
  // scripts
};
// eslint-disable-next-line import/prefer-default-export
export { default as wrapRootElement } from './src/app';
