// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-billing-jsx": () => import("./../../../src/pages/billing.jsx" /* webpackChunkName: "component---src-pages-billing-jsx" */),
  "component---src-pages-build-phases-jsx": () => import("./../../../src/pages/build-phases.jsx" /* webpackChunkName: "component---src-pages-build-phases-jsx" */),
  "component---src-pages-build-process-jsx": () => import("./../../../src/pages/build-process.jsx" /* webpackChunkName: "component---src-pages-build-process-jsx" */),
  "component---src-pages-case-study-jsx": () => import("./../../../src/pages/case-study.jsx" /* webpackChunkName: "component---src-pages-case-study-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-copyright-jsx": () => import("./../../../src/pages/copyright.jsx" /* webpackChunkName: "component---src-pages-copyright-jsx" */),
  "component---src-pages-cost-to-make-an-app-[id]-jsx": () => import("./../../../src/pages/cost-to-make-an-app/[id].jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-[id]-jsx" */),
  "component---src-pages-cost-to-make-an-app-course-1-jsx": () => import("./../../../src/pages/cost-to-make-an-app/course1.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-course-1-jsx" */),
  "component---src-pages-cost-to-make-an-app-course-2-jsx": () => import("./../../../src/pages/cost-to-make-an-app/course2.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-course-2-jsx" */),
  "component---src-pages-cost-to-make-an-app-dev-steps-jsx": () => import("./../../../src/pages/cost-to-make-an-app/dev-steps.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-dev-steps-jsx" */),
  "component---src-pages-cost-to-make-an-app-direct-cost-jsx": () => import("./../../../src/pages/cost-to-make-an-app/direct-cost.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-direct-cost-jsx" */),
  "component---src-pages-cost-to-make-an-app-estimate-jsx": () => import("./../../../src/pages/cost-to-make-an-app/estimate.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-estimate-jsx" */),
  "component---src-pages-cost-to-make-an-app-estimation-jsx": () => import("./../../../src/pages/cost-to-make-an-app/estimation.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-estimation-jsx" */),
  "component---src-pages-cost-to-make-an-app-expert-consult-jsx": () => import("./../../../src/pages/cost-to-make-an-app/expert-consult.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-expert-consult-jsx" */),
  "component---src-pages-cost-to-make-an-app-index-jsx": () => import("./../../../src/pages/cost-to-make-an-app/index.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-index-jsx" */),
  "component---src-pages-cost-to-make-an-app-intro-jsx": () => import("./../../../src/pages/cost-to-make-an-app/intro.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-intro-jsx" */),
  "component---src-pages-cost-to-make-an-app-more-jsx": () => import("./../../../src/pages/cost-to-make-an-app/more.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-more-jsx" */),
  "component---src-pages-cost-to-make-an-app-new-thank-you-jsx": () => import("./../../../src/pages/cost-to-make-an-app/new-thank-you.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-new-thank-you-jsx" */),
  "component---src-pages-cost-to-make-an-app-patch-jsx": () => import("./../../../src/pages/cost-to-make-an-app/patch.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-patch-jsx" */),
  "component---src-pages-cost-to-make-an-app-platforms-jsx": () => import("./../../../src/pages/cost-to-make-an-app/platforms.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-platforms-jsx" */),
  "component---src-pages-cost-to-make-an-app-product-marketing-jsx": () => import("./../../../src/pages/cost-to-make-an-app/product-marketing.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-product-marketing-jsx" */),
  "component---src-pages-cost-to-make-an-app-samples-jsx": () => import("./../../../src/pages/cost-to-make-an-app/samples.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-samples-jsx" */),
  "component---src-pages-cost-to-make-an-app-sign-up-jsx": () => import("./../../../src/pages/cost-to-make-an-app/sign-up.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-sign-up-jsx" */),
  "component---src-pages-cost-to-make-an-app-testimonial-sign-up-jsx": () => import("./../../../src/pages/cost-to-make-an-app/testimonialSignUp.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-testimonial-sign-up-jsx" */),
  "component---src-pages-cost-to-make-an-app-thank-you-jsx": () => import("./../../../src/pages/cost-to-make-an-app/thank-you.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-thank-you-jsx" */),
  "component---src-pages-cost-to-make-an-app-zero-jsx": () => import("./../../../src/pages/cost-to-make-an-app/zero.jsx" /* webpackChunkName: "component---src-pages-cost-to-make-an-app-zero-jsx" */),
  "component---src-pages-funding-jsx": () => import("./../../../src/pages/funding.jsx" /* webpackChunkName: "component---src-pages-funding-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jobs-jsx": () => import("./../../../src/pages/jobs.jsx" /* webpackChunkName: "component---src-pages-jobs-jsx" */),
  "component---src-pages-payments-jsx": () => import("./../../../src/pages/payments.jsx" /* webpackChunkName: "component---src-pages-payments-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-sample-apps-jsx": () => import("./../../../src/pages/sample-apps.jsx" /* webpackChunkName: "component---src-pages-sample-apps-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-testimonials-2-jsx": () => import("./../../../src/pages/testimonials2.jsx" /* webpackChunkName: "component---src-pages-testimonials-2-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thankYou.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-unsubscribe-jsx": () => import("./../../../src/pages/unsubscribe.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-jsx" */)
}

