const QUESTIONS = [

  {
    id: 'platforms',
    answerIds: {
      IOS: 'ios',
      ANDROID: 'android',
      WEB: 'web',
    },
  },
  {
    id: 'login',
    answerIds: {
      YES: 'yes',
      NO: 'no',
    },
  },
  {
    id: 'design',
    answerIds: {
      NONE: 'none',
      WRITTEN: 'written',
      PAPER: 'paper',
      REF_APP: 'ref-app',
      DIGITAL: 'digital',
    },
  },
  {
    id: 'level-of-detail',
    answerIds: {
      BARE_BONES: 'bare-bones',
      DETAILED: 'detailed',
      BEAUTIFUL: 'beautiful',
    },
  },
  {
    id: 'features',
    answerIds: {
      NONE: 'none',
      AR: 'ar',
      GAMING: 'gaming',
      ADS: 'ads',
      STREAMING: 'streaming',
      CRM: 'crm',
      E_COMMERCE: 'e_commerce',
      APIS: 'apis',
      PAYMENTS: 'payments',
      CRYPTO: 'crypto',
      COMMENTS: 'comments',
      MESSAGING: 'messaging',
      AI: 'artificial_inteligence',
      other: '',
    },
  },
  {
    id: 'revenue_model',
    answerIds: {
      Advertisements: 'advertisements',
      Monthly_or_Annual_fees: 'monthly_or_annual_fees',
      Profit_on_Sales: 'profit_on_sales',
      other: ''
    },
  },
  // {
  //   id: 'prize',
  //   answerIds: {
  //     YES: 'yes',
  //     NO: 'no',
  //   },
  // },
  // {
  //   id: 'technology',
  //   answerIds: {
  //     YES: 'yes',
  //     NO: 'no',
  //   },
  // },
  // {
  //   id: 'development',
  //   answerIds: {
  //     YES: 'yes',
  //     NO: 'no',
  //   },
  // },
  {
    id: 'marketing',
    answerIds: {
      YES: 'yes',
      NO: 'no',
    },
  },
  // {
  //   id: "funding",
  //   answerIds: {
  //     GRANT: "grant",
  //     FUND: "fund",
  //     NONE: "none",
  //   },
  // },
  // {
  //   id: 'revenue',
  //   answerIds: {
  //     u100k: 'u100k',
  //     u1m: 'u1m',
  //     u10m: 'u10m',
  //     a10m: 'a10m',
  //   },
  // },
  {
    id: 'value',
    answerIds: {
      u1k: 'u1k',
      u10k: 'u10k',
      u100k: 'u100k',
      a100k: 'a100k',
    },
  },

  // {
  //   id: "context",
  //   answerIds: {
  //     COMPANY: "company",
  //     PERSONAL: "personal",
  //     CLIENT: "client",
  //     ACADEMIC: "academic",
  //     OTHER: "other",
  //   },
  // },
  // {
  //   id: 'properties',
  //   answerIds: {
  //     Scalability: 'scalability',
  //     Security: 'security',
  //     UserFriendly: 'userfriendly',
  //     other: '',
  //   },
  // },
  {
    id: 'objective',
    answerIds: {
      Quality: 'high quality',
      Price: 'Low price',
      Speed: 'Fast delivery',
    },
  },

  {
    id: 'signupGreeting',
    answerIds: {
      UPDATED: 'updated',
    },
  },

  // {
  //   id: 'bonuses',
  //   answerIds: {
  //     breakdown: 'breakdown',
  //     credit: 'credit',
  //     development: 'development',
  //     consult: 'consult',
  //     other: '',
  //   },
  // },
];

export default QUESTIONS;
